import React, {memo, useState, useEffect} from 'react';
import {selectCurrentUser} from 'features/users/selectors';
import {selectCurrentUserPermissions} from 'features/permissions/selectors';
import {intersection} from 'utils/helpers';
import {useRouter, usePathname, useParams} from 'next/navigation';
import {useSelector} from 'react-redux';
import NotFound from 'features/generic/components/NotFound/NotFound';
import {selectAccountByIdNoWithRouter} from 'features/accounts/selectors';

export const canSubaccount = (user, account) => {
    return (
        user.permissions.includes('account-manage-subaccounts') &&
        account?.type === 'subaccount' &&
        account?.parent_id === user.account_id
    );
};

let AuthRoute = ({element: Component, permissions, authorize, children, ...rest}) => {
    const router = useRouter();
    const pathname = usePathname();
    const user = useSelector(selectCurrentUser);
    const userPermissions = useSelector(selectCurrentUserPermissions);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const {accountId} = useParams();
    const account = useSelector((state) =>
        selectAccountByIdNoWithRouter(state, accountId),
    );

    let shouldRenderComponent = false;

    if (permissions) {
        shouldRenderComponent =
            intersection(permissions, userPermissions).length === permissions.length;
    }

    if (authorize) {
        shouldRenderComponent = authorize(user, account, pathname);
    }
    if (!shouldRenderComponent) {
        // user is not authenticated
        return <NotFound statusCode={403} />;
    }

    return children;
};

export default memo(AuthRoute);
