import {createSelector} from 'reselect';
import {
    sortDescending,
    sortAscending,
    lower,
    omit,
    isEmpty,
    getEventType,
    sortNum,
} from 'utils/helpers';
import {MONTHS} from 'core/constants';
import {monthValid, yearGteCurrent} from '../../utils/validateForm';
import {selectListingById} from '../listings/selectors';

const getPayments = (state) => state.billing.payments;
const getCards = (state) => state.billing.cards;
const getAccounts = (state) => state.accounts.entities;
const getTransactions = (state) => state.billing.transactions;
const getStatuses = (state) => state.billing.statuses;
const getTransactionEventType = (state, transaction) => getEventType(transaction);

export const selectIterableCards = createSelector([getCards], (cards) => {
    return Object.keys(cards).map((id) => cards[id]);
});

export const selectSortedPrimaryCards = createSelector([selectIterableCards], (cards) => {
    return [...cards.sort((a, b) => b.default)];
});

export const selectCardsByAccountId = createSelector(
    [selectSortedPrimaryCards, (state, accountId) => accountId],
    (cards, accountId) => {
        return cards.filter((card) => card.account_id === accountId);
    },
);

export const selectIterableStatuses = createSelector([getStatuses], (statuses) => {
    return Object.keys(statuses).map((key) => {
        return {
            name: key,
            display: statuses[key],
        };
    });
});

export const selectHasValidCard = createSelector(
    [selectSortedPrimaryCards, (state, listing) => listing],
    (cards, listing) => {
        const accountCards = cards.filter(
            (card) => card.account_id === listing?.account_id,
        );
        let hasValidCard = false;
        if (accountCards?.length) {
            accountCards.forEach((card) => {
                if (
                    card.default &&
                    yearGteCurrent(card.cc_expiration_year) === null &&
                    monthValid(card.cc_expiration_month) === null
                ) {
                    hasValidCard = true;
                }
            });
        }
        return hasValidCard;
    },
);

export const selectEventStatuses = createSelector(
    [selectIterableStatuses, getTransactionEventType],
    (statuses, eventType) => {
        return statuses.filter((status) => status.name.match(eventType));
    },
);

export const selectBillableStatuses = createSelector(
    [selectEventStatuses],
    (statuses) => {
        return statuses.filter((status) => status.name.match('valid'));
    },
);

export const selectNotBillableStatuses = createSelector(
    [selectEventStatuses],
    (statuses) => {
        return statuses.filter((status) => !status.name.match('valid'));
    },
);

export const selectPaymentsWithMonths = createSelector([getPayments], (payments) => {
    if (isEmpty(payments)) return [];
    return payments.reduce((acc, cur, i) => {
        const split = cur.created_at.split('-');
        const thisMonth = split[1];

        let lastMonthSplit;
        let lastMonth;

        if (i === 0) {
            acc.push(MONTHS[+thisMonth - 1]);
        } else if (i > 0) {
            lastMonthSplit = payments[i - 1].created_at.split('-');
            lastMonth = lastMonthSplit[1];
            if (thisMonth !== lastMonth) acc.push(MONTHS[+thisMonth - 1]);
        }

        acc.push(cur);

        return acc;
    }, []);
});
