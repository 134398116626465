'use client'
import {COLORS, darken} from 'core/styles';
import styled, {keyframes} from 'styled-components';

// export const container = (props) => {
//     return Object.assign(
//         {
//             display: 'inline-block',
//         },
//         props.style,
//     );
// };

export const Container = styled.div`
    display: inline-flex;
    align-items: center;
    ${({style}) => style};
`;

export const SvgContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 24px;
`;

// export let svgContainer = {
//     display: 'inline-block',
//     verticalAlign: 'middle',
// };

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

export const StyledSvg = styled.svg`
    animation: ${rotate} 700ms linear infinite;
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    max-width: 45px;
    max-height: 45px;
`;

// let rotate = {
//     '0%': {transform: 'rotate(0deg)'},
//     '100%': {transform: 'rotate(359deg)'}
// };

// export let svg = {
//     animation: `${rotate} 700ms linear infinite`,
//     width: '100%',
//     height: '100%',
//     display: 'inline-block',
//     verticalAlign: 'middle',
//     maxWidth: '45px',
//     maxHeight: '45px'
// }

// export let text = (props) => {
//     return Object.assign(
//         {
//             color: darken(COLORS.grayFont, 15),
//             opacity: 0.6,
//             fontWeight: 'bold',
//             fontSize: '14px',
//             marginLeft: '15px',
//             display: 'inline-block',
//             verticalAlign: 'middle',
//         },
//         props.messageStyle,
//     );
// };

export const Text = styled.div`
    color: ${darken(COLORS.grayFont, 15)};
    opacity: 0.6;
    font-weight: bold;
    font-size: 14px;
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
    ${({messageStyle}) => messageStyle};
`;
