import React from 'react';
import Link from 'next/link';
import Content from 'features/generic/components/Content/Content';
import {withRouter} from '../../../../utils/helpers';
import * as styles from './styles';
import {useRouter} from 'next/navigation';
import Button from '../Button/Button';

const getMessage = (statusCode) => {
    statusCode = statusCode.toString();

    switch (statusCode) {
        case '404':
            return 'the page could not be found';
        case '403':
            return 'you are not permitted to view this page';
        case '500':
            return 'an unexpected error occurred';
        default:
            return 'the page could not be found';
    }
};

const NotFound = ({statusCode = 404, error, reset = () => {}}) => {
    const router = useRouter();
    const hasError = statusCode === 500; // client or ssr js error

    return (
        <Content style={styles.content}>
            <div style={styles.imageContainer}>
                <div style={styles.oops}>{statusCode}</div>
            </div>
            <div>
                <h3 style={styles.message}>We're sorry, {getMessage(statusCode)}</h3>
            </div>
            <Button style={styles.back} onClick={hasError ? () => reset() : router.back}>
                {hasError ? 'Try Again' : 'Back'}
            </Button>
            <Link style={styles.dashboardLink} href="/dashboard">
                Return to Overview
            </Link>
        </Content>
    );
};

export default withRouter(NotFound);
