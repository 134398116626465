import {bp, MAX_WIDTH} from 'core/styles';
import styled from 'styled-components';

export let ContentContainer = styled.div`
    padding: 15px;

    ${bp('md')} {
        padding: 12px 30px;
    }
    ${(props) => (props.$css ? props.$css : '')};
`;

export let ContentInner = styled.div`
    max-width: ${MAX_WIDTH};
`;
