import {get, post, patch, del} from 'core/configureRequest';
import {notifySuccessWithTimeout, notifyError} from 'features/notification/slice';
import {showModal, hideModal} from 'features/modal/slice';
import {
    isEmpty,
    omit,
    getEventType,
    getEventId,
    include,
    getDetailedError,
    maybeIncludeAnalytics,
    get as getProp,
} from 'utils/helpers';
import {SubmissionError} from 'redux-form';
import {getFormatted, getLastMonth, getTomorrow} from 'utils/dates';
import {DISPUTE_REASONS} from 'core/constants';
import {getAccount, receiveAccountFailure} from 'features/accounts/actions';
import {CR_START_DATE, EVENT_CATEGORIES} from 'core/constants';

export const requestTransactions = () => ({
    type: 'REQUEST_TRANSACTIONS',
});

export const receiveTransactionsFailure = () => ({
    type: 'RECEIVE_TRANSACTIONS_FAILURE',
});

const receiveCardsFailure = () => ({
    type: 'RECEIVE_CARDS_FAILURE',
});

export const receiveTransactions = (accountId, transactions, pagination) => {
    return maybeIncludeAnalytics(getProp(pagination, 'page') !== 1, {
        type: 'RECEIVE_TRANSACTIONS',
        accountId,
        transactions,
        pagination,
        meta: {
            analytics: {
                category: EVENT_CATEGORIES.Transactions,
            },
        },
    });
};

export const requestCreateDispute = () => ({
    type: 'REQUEST_CREATE_DISPUTE',
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Transactions,
            label: 'Create Dispute',
        },
    },
});

const requestCard = () => ({
    type: 'REQUEST_CARD',
});

const requestCards = () => ({
    type: 'REQUEST_CARDS',
});

const savePrimaryCardId = (id) => ({
    type: 'SAVE_PRIMARY_CARD_ID',
    id,
});

const receiveCard = (card) => ({
    type: 'RECEIVE_CARD',
    card,
});

const receiveCards = (accountId, cards) => ({
    type: 'RECEIVE_CARDS',
    accountId,
    cards,
});

export const requestDeleteCard = () => ({
    type: 'REQUEST_DELETE_CARD',
});

export const receiveDeleteCard = (cardId) => ({
    type: 'RECEIVE_DELETE_CARD',
    cardId,
});

export const receiveDeleteCardFailure = () => ({
    type: 'RECEIVE_DELETE_CARD_FAILURE',
});

const requestBillableStatuses = () => ({
    type: 'REQUEST_BILLABLE_STATUSES',
});

const receiveBillableStatusesFailure = () => ({
    type: 'RECEIVE_BILLABLE_STATUSES_FAILURE',
});

export const receiveBillableStatuses = (statuses) => ({
    type: 'RECEIVE_BILLABLE_STATUSES',
    statuses,
});

export const requestBalanceRefill = () => ({
    type: 'REQUEST_BALANCE_REFILL',
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Billing,
        },
    },
});

const requestPaymentHistory = () => ({
    type: 'REQUEST_PAYMENT_HISTORY',
});

const receivePaymentHistory = (records, pagination) => ({
    type: 'RECEIVE_PAYMENT_HISTORY',
    records,
    pagination,
});

const requestPaymentHistoryFailure = (e) => ({
    type: 'RECEIVE_PAYMENT_HISTORY_FAILURE',
});

const getCards = (accountId) => (dispatch, getState) => {
    dispatch(requestCards());

    return get(`/proxy/api/billing/card/?account_id=${accountId}`)
        .then((data) => {
            dispatch(receiveCards(accountId, data.data.cards));

            return data;
        })
        .catch((e) => {
            dispatch(notifyError('Could not get payment information'));
            dispatch(receiveCardsFailure());

            return Promise.reject(
                e && e.message ? e.message : 'Could not get payment info',
            );
        });
};

export const getCardsIfNeeded = (accountId) => (dispatch, getState) => {
    const state = getState();
    const account = state.accounts.entities[accountId];
    const cards = Object.keys(state.billing.cards);

    if (account && account.cc_ids) {
        dispatch(receiveCardsFailure());
        return Promise.resolve(account[accountId]);
    }

    return dispatch(getCards(accountId));
};

export const editCard = (values) => (dispatch, getState) => {
    /**
     * Values initialized with e.g., **** 3221
     * Check if input still has '*'
     * if so, remove
     *
     * Required fields: cc_number, cvc_number, billing_zip_code, expiration
     */

    return patch(`/proxy/api/billing/card/${values.cc_id}/`, {
        ...include(values, [
            'account_id',
            // values.cc_cvc.match(/\*/gi) ? '' : 'cc_cvc',
            // values.cc_number.match(/\*/gi) ? '' : 'cc_number',
            'billing_zip_code',
            'cc_expiration_month',
            'cc_expiration_year',
            'default',
        ]),
    })
        .then((data) => {
            dispatch(receiveCard(data.data.card));

            return data;
        })
        .catch((e) => Promise.reject(e.message || 'Could not edit card'));
};

export const addCard = (values) => (dispatch, getState) => {
    /**
     *
     * Required fields: cc_first_name, cc_last_name, address,
     */
    const fields = include(values, [
        'account_id',
        'city',
        'region',
        'street_address',
        'billing_zip_code',
        'cc_cvc',
        'cc_number',
        'cc_expiration_year',
        'cc_expiration_month',
        'cc_first_name',
        'cc_last_name',
    ]);

    return post('/proxy/api/billing/card/', fields)
        .then((data) => {
            dispatch(receiveCard(data.data.card));
            return data;
        })
        .then((data) => {
            dispatch(getAccount(fields.account_id));
            return data;
        })
        .catch((e) => {
            dispatch(receiveAccountFailure());
            return Promise.reject(e);
        });
};

export const deleteCard = (card) => (dispatch, getState) => {
    if (!card) {
        console.warn('Cannot delete card without card id', card);

        return Promise.reject('Cannot delete card');
    }

    dispatch(
        showModal({
            type: 'DELETE_CARD',
            props: {
                message: `Are you sure you want to delete ${
                    card.cc_type + ' ' + card.cc_last_four
                }`,
                handleClose: () => dispatch(hideModal()),
                handleConfirm: () => {
                    return del(`/proxy/api/billing/card/${card.cc_id}/`)
                        .then((data) => {
                            dispatch(receiveDeleteCard(card.cc_id));
                            dispatch(hideModal());
                            dispatch(notifySuccessWithTimeout(data.message));

                            return data;
                        })
                        .catch((e) => {
                            dispatch(receiveDeleteCardFailure());
                            dispatch(hideModal());
                            dispatch(notifyError(e.message || 'Could not delete card'));

                            return Promise.reject(e);
                        });
                },
            },
        }),
    );
};

export const getPaginatedTransactions = (accountId, page) => (dispatch, getState) => {
    dispatch(requestTransactions());

    const {pagination} = getState().billing;

    if (accountId !== getState().billing.accountId) {
        page = 1;
    } else if (!page) {
        page = !isEmpty(pagination) ? pagination.page : 1;
    }

    return post('/proxy/api/reporting/report/', {
        report_name: 'tracking-and-transaction-by-event',
        options: {
            format: 'json',
            paginate: {
                page,
                perpage: 30,
            },
        },
        filters: {
            created_at: {
                from: CR_START_DATE,
            },
            account_id: {eq: accountId},
        },
    })
        .then((data) => {
            if (!data.data.records || !data.data.records.hasOwnProperty('length')) {
                dispatch(receiveTransactionsFailure());
                return;
            }

            dispatch(
                receiveTransactions(accountId, data.data.records, data.data.pagination),
            );

            return data;
        })
        .catch((e) => {
            dispatch(receiveTransactionsFailure());
            return Promise.reject(e);
        });
};

export const createDispute = (values) => (dispatch, getState) => {
    dispatch(requestCreateDispute());

    const accountId = values.account_id;

    return post('/proxy/api/billing/dispute/', {
        tracking_id: values.tracking_id,
        billable_reason: values.billable_reason,
        billable: !values.billable,
        comments: values.comments,
        reviewer_user_id: getState().users.currentUserId,
    })
        .then((data) => {
            dispatch(getPaginatedTransactions(values.account_id));

            dispatch(notifySuccessWithTimeout('Dispute has been recorded'));
            setTimeout(() => dispatch(hideModal()), 600);

            return data;
        })
        .catch((e) =>
            Promise.reject(
                new SubmissionError({
                    _error: getDetailedError(e) || 'Could not create dispute',
                }),
            ),
        );
};

export const getTransaction = (accountId, trackingId) => (dispatch, getState) => {
    if (!accountId) return console.warn('Must provide an account ID');
    if (!trackingId) return console.warn('Must provide tracking id');

    dispatch(requestTransactions());

    return post('/proxy/api/reporting/report/', {
        report_name: 'tracking-and-transaction-by-event',
        options: {format: 'json'},
        filters: {
            tracking_id: {eq: trackingId},
            account_id: {eq: accountId},
        },
    })
        .catch((e) => console.warn(e))
        .then((data) => {
            if (!data.data.records || !data.data.records.hasOwnProperty('length')) {
                dispatch(receiveTransactionsFailure());
                return;
            }

            dispatch(receiveTransactions(accountId, data.data.records));

            return data;
        })
        .catch((e) => {
            dispatch(notifyError(e.message || 'Cannot get report'));
            dispatch(receiveTransactionsFailure());
        });
};

export const manualBalanceRefill = (values) => (dispatch, getState) => {
    const valuesToSubmit =
        values.source === 'check' ? omit(values, 'credit_card_id') : values;

    dispatch(requestBalanceRefill());

    return post('/proxy/api/billing/credit/', {...valuesToSubmit})
        .then((data) => {
            dispatch(getPaginatedTransactions(values.account_id));
            dispatch(getAccount(values.account_id));

            dispatch(notifySuccessWithTimeout('Account successfully charged!'));
        })
        .catch((e) => {
            return Promise.reject(e);
        });
};

/**
 * Confirm or deny transaction dispute requests
 */
export const submitReviewDispute = (transaction, approved) => (dispatch, getState) => {
    if (!transaction.dispute_id) {
        console.warn('No dispute ID found', transaction);
        return;
    }

    const accountId = transaction.account_id;

    if (!accountId) {
        console.warn('Cannot get account ID from dispute resolution');

        return Promise.reject(transaction);
    }

    return patch(`/proxy/api/billing/dispute/${transaction.dispute_id}/`, {
        admin_approved: approved,
    }).catch((e) => Promise.reject(e));
};

export const getBillableStatuses = () => (dispatch, getState) => {
    dispatch(requestBillableStatuses());

    return get('/proxy/api/statuses/')
        .then((data) => {
            const filteredStatuses = data.data.statuses.filter(
                (status) => status.type === 'tracking_billable_reason',
            );

            dispatch(receiveBillableStatuses(filteredStatuses));

            return data;
        })
        .catch((e) => dispatch(receiveBillableStatusesFailure()));
};

export const refundTransaction = (values) => (dispatch, getState) => {
    return post('/proxy/api/billing/debit/', values);
};

export const getPaymentHistory =
    (accountId, page = 1) =>
    (dispatch, getState) => {
        dispatch(requestPaymentHistory());

        return post('/proxy/api/reporting/report/', {
            report_name: 'payment-history',
            options: {
                format: 'json',
                paginate: {
                    page: page,
                    perpage: 30,
                },
            },
            filters: {
                account_id: {eq: accountId},
            },
        })
            .then((data) => {
                dispatch(
                    receivePaymentHistory(data.data.records, {
                        page: data.data.pagination.page,
                        count: data.data.pagination.perpage,
                        total: data.data.pagination.total,
                    }),
                );
            })
            .catch((e) => {
                dispatch(
                    notifyError(
                        e && e.message ? e.message : 'Could not get payment history',
                    ),
                );
                dispatch(requestPaymentHistoryFailure());
                return Promise.reject(e);
            });
    };
