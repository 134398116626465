import React from 'react';
import LoadingIcon from 'features/generic/components/LoadingIcon';

import {SvgContainer, Container, Text} from './styles';

const Loading = (props) => {
    return (
        <Container style={props.style}>
            <SvgContainer>
                <LoadingIcon />
            </SvgContainer>
            {props.message && <Text style={props.messageStyle}>{props.message}</Text>}
        </Container>
    );
};

export default Loading;
